.ant-input:focus{
	box-shadow:0 0 0 2px rgba(255,255,255,0.6);
	border-color:#fff!important;
}
.ant-input:hover{
	border-color:#fff!important;
}
.ant-input{
	height:5vh;
	font-size:1.8vh;padding:0.7vh;
	border-radius: 0.5vh;
}
.ant-input-affix-wrapper .ant-input:not(:first-child){
	padding-left:5vh;
}
.ant-input-affix-wrapper .ant-input-prefix{
	left:1.8vh;
}

.loginBtn{
	color: #3e3a39;
    width: 100%;
    height: 5vh;
    font-size: 1.8vh;
    text-align: center;
    line-height: 5vh;
    border-radius: 0.5vh;
    background-color: #fff371;
    transition: 0.5s;
}
.loginBtn:hover,.loginBtn:focus{
	background-color: #fff371;
	color:#3e3a39;
	border-color:#fff371;
}
