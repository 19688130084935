.tagBlock{
	display:flex;
	flex-direction: column;
	
}
.tag{
	align-self: baseline;
	margin:5px;
}
.table{
border:1px solid #ebedf0;
margin:30px 0;
padding:16px 16px 60px 16px;
border-radius: 5px;
}
.title{
	display: flex;
	align-items: center;
	justify-content: space-between;
	height:60px;
	margin:30px 0;
}
.breadcrumb{
	display: flex;
	align-items: center;
	height:28px;
}
.createTable{
	padding:30px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom:1px solid #ebedf0;
	position: relative;
}
.createContent{
	border:1px solid #ebedf0;
	border-radius: 5px;
	margin:30px 0;

}
.createColumn{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px;
	flex:1;
	position: relative;

}
.columnContent{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction:column;
}
.divider:before{
content: '';
position: absolute;
left:0;
width:1px;
top:10px;
bottom:10px;
background-color: #e5e5e5;
}
.createImg{
	width:80px;
	height: 80px;
	margin-bottom: 10px;
	user-select: none;
}
.cloumnTitle{
	margin-bottom: 20px
}
.createFooter{
	display: flex;
	justify-content: center;
	align-items: center;
	padding:24px 0;
}
.columnSpan{
	width:240px;
	height: 100px;
	font-size: 12px;
	color: #949494;
	padding:20px;
}
.strategyTable{
	border:1px solid #ebedf0;
	padding:16px 24px;
	border-radius: 5px;
	user-select: none;
	margin-bottom: 16px;
	position:relative;
}
.strategyTable h4{
	color:#949494;
}
.strategyContent{
padding:25px 0;
}
.strategyTableFooter{
	padding:15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 100%
}
.strategyMale{
	font-size: 12px;
	margin-right: 20px;
	position: relative;
	padding-left: 10px;

}
.strategyFemale{
	font-size: 12px;
	position: relative;
	padding-left: 10px;
	
}
.strategyMale:before{
	content: '';
	width:5px;
	height: 5px;
	position: absolute;
	border-radius: 50%;
	left:0;
	top:7px;
	background-color: rgba(24, 144, 255, 0.85);
}
.strategyFemale:before{
	content: '';
	width:5px;
	height: 5px;
	position: absolute;
	left:0;
	top:7px;
	border-radius: 50%;
	background-color: rgba(214, 52, 83, 0.85);
}
.strategyTableCustom{
	padding:16px 0;
	display: flex;
	justify-content: center;
}
.strategyTableCustom .first{
	width:80px;
}
.first .tableBlock{
	
	background-color: #f7f7f7;
	
}
.first span{
	font-size: 14px;
	font-weight: 500;
}
.tableBlock{
	height:44px;
	display: flex;
	justify-content: center;
	align-items: center;
	border:0.5px solid #ebedf0;
}
.strategyTableCustom .other{
	flex:1;
}
.tableSetting{
	position: absolute;
	right:16px;
	top:16px;
	cursor: pointer;
}
.tableSettingIcon{
	color:rgba(24, 144, 255,1);
}
.tableSettingIcon:hover{
	color:rgba(24, 144, 255, 0.7);
}
.strategyClass{
	position: absolute;
	right:22px;
	top:20px;
}
.strategyRules{
	font-size: 12px;
	color:rgba(24, 144, 255, 1);
	cursor: pointer;
}
.strategyRules:hover{
	color:rgba(24, 144, 255, 0.85);
}
.strategyClassTable{
	padding:10px;
	margin-top: 20px;
	border:1px solid #ebedf0;
}
.strategyClassTableRow{
	display: flex;
	justify-content: space-around;

}
.strategyClassTableRowHead{
	display: flex;
	justify-content: space-around;
	border-bottom:1px solid #ebedf0;
}
.strategyClassTableRowBlock{
width:20%;
padding:10px;
display: flex;
align-items: center;
}
.selectProgress{
	padding:8% 20%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}
.selectProgressAnimate{
height: 2px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 40px;
}
.selectProgressAnimate .animate{
	    background: linear-gradient(to right, rgba(24, 144, 255, 0) 0%, #1890ff 100%);
	        height: 100%;
    width: 64px;
        animation: bannerTitleLine 3s ease-in-out 0s infinite;
   
}
@keyframes bannerTitleLine{
	0%, 25% {
    -webkit-transform: translateX(-64px);
    transform: translateX(-64px);
}
75%, 100% {
    -webkit-transform: translateX(45vw);
    transform: translateX(45vw);
}
}

.progressUpSide{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding:30px 0;
}
.progressDownSide{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.progressDownSide .footerButton{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width:160px;
	border-top:1px solid #ebedf0;
	padding-top:20px;
}
.footerButton .tableSettingIcon{
	font-size:18px;
	cursor: pointer;
}