 .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.trigger:hover {
  opacity: .7
}

.logo {
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;
}
 .navTitle{
  transition: .2s;
 }
 .navTitle:hover{
  color:#40a9ff;
 }
 .logoTitle{
  margin:0 15px!important;
 }
 .logoBlock{
  width: 18%!important;
  user-select: none;
 }
.navIcon{
 
  display: flex;
  align-items:center;
  justify-content: center;


}
@media screen and (max-width: 993px) {
   
 .navTitle{
  display: none;
 }
 .logoBlock{
  width: 80px;
 }
 .logoTitle{
  display: none!important;
 }
 .basicContent{
  padding:0 15px!important;
 }
}
.basicContent{
  background-color: #fff!important;
  padding:0 15px;
}
.navTitle:before{
content: "";
height:24px;
left:0;
top:20px;
position: absolute;
width:1px;
background-color: #e8e8e8;
}
.titleh5{
  display: inline-block;
    text-indent: 8px;
    border-left: 2px solid #88B7E0;
    margin-right: 8px;
    vertical-align: top;
  margin-top: 8px;margin-bottom: 8px;
}
.titlerow{
    border-bottom: 1px solid #DDD;
    padding: 16px 0px;
    min-height: 70px;
}
.titlebox{

}
.pull_left{float:left !important;}
.pull_right{float:right !important;}
.console_title_static{margin: 8px;}