body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

 ._2MtIJM2fhbmCT-slM2edJS {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

._2MtIJM2fhbmCT-slM2edJS:hover {
  opacity: .7
}

._3mkR7H_2rNPUBWrlD0-lDr {
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;
}
 ._11hykCX70U_KDLtzPFrJyt{
  transition: .2s;
 }
 ._11hykCX70U_KDLtzPFrJyt:hover{
  color:#40a9ff;
 }
 ._3-VQC_7uohVFyJoV18CxDW{
  margin:0 15px!important;
 }
 ._2TI8we2lDJcxjR4ubwgxjM{
  width: 18%!important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
 }
._3pSJ2BRkYO6rIWmz4LkbQP{
 
  display: flex;
  align-items:center;
  justify-content: center;


}
@media screen and (max-width: 993px) {
   
 ._11hykCX70U_KDLtzPFrJyt{
  display: none;
 }
 ._2TI8we2lDJcxjR4ubwgxjM{
  width: 80px;
 }
 ._3-VQC_7uohVFyJoV18CxDW{
  display: none!important;
 }
 ._2dsk9cEmljJIYEYBHNA4-l{
  padding:0 15px!important;
 }
}
._2dsk9cEmljJIYEYBHNA4-l{
  background-color: #fff!important;
  padding:0 15px;
}
._11hykCX70U_KDLtzPFrJyt:before{
content: "";
height:24px;
left:0;
top:20px;
position: absolute;
width:1px;
background-color: #e8e8e8;
}
.DY4-kFE94IbTUuCFRt_Ho{
  display: inline-block;
    text-indent: 8px;
    border-left: 2px solid #88B7E0;
    margin-right: 8px;
    vertical-align: top;
  margin-top: 8px;margin-bottom: 8px;
}
.KSbeD_V-w3eHahnjqIws7{
    border-bottom: 1px solid #DDD;
    padding: 16px 0px;
    min-height: 70px;
}
._1b8Ldpb3iSXcr4qlgk5iIl{

}
._2yasv_azLwaCt6Eg1SXrzv{float:left !important;}
._1mTl3JAPVa1b8iAIMqc4fF{float:right !important;}
.yBtzMnyzV7eJIeOn-_3YK{margin: 8px;}
._2G3-o7wRheZV7QHJ1Gyq-1:focus{
	box-shadow:0 0 0 2px rgba(255,255,255,0.6);
	border-color:#fff!important;
}
._2G3-o7wRheZV7QHJ1Gyq-1:hover{
	border-color:#fff!important;
}
._2G3-o7wRheZV7QHJ1Gyq-1{
	height:5vh;
	font-size:1.8vh;padding:0.7vh;
	border-radius: 0.5vh;
}
._35iY_gqv3v5n_nPqjlvDhE ._2G3-o7wRheZV7QHJ1Gyq-1:not(:first-child){
	padding-left:5vh;
}
._35iY_gqv3v5n_nPqjlvDhE ._25gqIm_3o4NjVi0aog8Eix{
	left:1.8vh;
}

._8aHBrvOaBypfdTfybaCL4{
	color: #3e3a39;
    width: 100%;
    height: 5vh;
    font-size: 1.8vh;
    text-align: center;
    line-height: 5vh;
    border-radius: 0.5vh;
    background-color: #fff371;
    transition: 0.5s;
}
._8aHBrvOaBypfdTfybaCL4:hover,._8aHBrvOaBypfdTfybaCL4:focus{
	background-color: #fff371;
	color:#3e3a39;
	border-color:#fff371;
}

._36DkH4xSkjITmKlEqjEcqW{
	display:flex;
	flex-direction: column;
	
}
._2ffOhvUkoTA67MNpARaQaU{
	align-self: baseline;
	margin:5px;
}
._6bhXncx1V7ZkwtrlJISx-{
border:1px solid #ebedf0;
margin:30px 0;
padding:16px 16px 60px 16px;
border-radius: 5px;
}
._2Oc2ff6M86YiI1fNXlGEWE{
	display: flex;
	align-items: center;
	justify-content: space-between;
	height:60px;
	margin:30px 0;
}
._2UpKJFX4KBd_ucnpTtj2ur{
	display: flex;
	align-items: center;
	height:28px;
}
.IjTW40ZenB5iYXgvwTS1v{
	padding:30px 0;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom:1px solid #ebedf0;
	position: relative;
}
._8v2XFh5wQDphBV2OYpp40{
	border:1px solid #ebedf0;
	border-radius: 5px;
	margin:30px 0;

}
._2mJmCH3QtV25BANrY0VNnr{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 16px;
	flex:1 1;
	position: relative;

}
._1vEh8Vt3ZRjGbRHnjxw8KI{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction:column;
}
._1uQE0LrFt_F-KXzZDspUau:before{
content: '';
position: absolute;
left:0;
width:1px;
top:10px;
bottom:10px;
background-color: #e5e5e5;
}
._3giNclGrwSkDAqNkrWqTjN{
	width:80px;
	height: 80px;
	margin-bottom: 10px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
._2XDgw7x8TQ0rudoqQHyZrW{
	margin-bottom: 20px
}
.X9nBCcb8u9GCkf32XlYWQ{
	display: flex;
	justify-content: center;
	align-items: center;
	padding:24px 0;
}
._1AKqx1UsFQxtUi9GLFjKkQ{
	width:240px;
	height: 100px;
	font-size: 12px;
	color: #949494;
	padding:20px;
}
._38937DAv_9bv1H2T8cG8qD{
	border:1px solid #ebedf0;
	padding:16px 24px;
	border-radius: 5px;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	margin-bottom: 16px;
	position:relative;
}
._38937DAv_9bv1H2T8cG8qD h4{
	color:#949494;
}
._2RKpPa4DQz6ZFyEvdltzKQ{
padding:25px 0;
}
._2JQWRX9PsD9JhSY-0Uqaad{
	padding:15px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 100%
}
._3fhEL_8yGlcctVL87Do6j5{
	font-size: 12px;
	margin-right: 20px;
	position: relative;
	padding-left: 10px;

}
._3VXBseIm5zCPze9s-1d6Wm{
	font-size: 12px;
	position: relative;
	padding-left: 10px;
	
}
._3fhEL_8yGlcctVL87Do6j5:before{
	content: '';
	width:5px;
	height: 5px;
	position: absolute;
	border-radius: 50%;
	left:0;
	top:7px;
	background-color: rgba(24, 144, 255, 0.85);
}
._3VXBseIm5zCPze9s-1d6Wm:before{
	content: '';
	width:5px;
	height: 5px;
	position: absolute;
	left:0;
	top:7px;
	border-radius: 50%;
	background-color: rgba(214, 52, 83, 0.85);
}
._5ewv6l-SJ6TrDRfXBVFa5{
	padding:16px 0;
	display: flex;
	justify-content: center;
}
._5ewv6l-SJ6TrDRfXBVFa5 .HJfs-xlQghDu5HSgNnX83{
	width:80px;
}
.HJfs-xlQghDu5HSgNnX83 ._3eV9yiM4m48l2uJDNJDIXV{
	
	background-color: #f7f7f7;
	
}
.HJfs-xlQghDu5HSgNnX83 span{
	font-size: 14px;
	font-weight: 500;
}
._3eV9yiM4m48l2uJDNJDIXV{
	height:44px;
	display: flex;
	justify-content: center;
	align-items: center;
	border:0.5px solid #ebedf0;
}
._5ewv6l-SJ6TrDRfXBVFa5 .IkMqoEW18lbdPxohUJYjZ{
	flex:1 1;
}
.UDo9wFMdjSIJB8kbwmAcY{
	position: absolute;
	right:16px;
	top:16px;
	cursor: pointer;
}
.RPATmMx9dSa_cf7y3DPyU{
	color:rgba(24, 144, 255,1);
}
.RPATmMx9dSa_cf7y3DPyU:hover{
	color:rgba(24, 144, 255, 0.7);
}
._37icpO2_TULQIxYAyK8HVp{
	position: absolute;
	right:22px;
	top:20px;
}
._3L5GBP7wMvhQHNlIh4PRaq{
	font-size: 12px;
	color:rgba(24, 144, 255, 1);
	cursor: pointer;
}
._3L5GBP7wMvhQHNlIh4PRaq:hover{
	color:rgba(24, 144, 255, 0.85);
}
._3xtEC0N1GzgSrOCGjwJPeP{
	padding:10px;
	margin-top: 20px;
	border:1px solid #ebedf0;
}
.Wnhr0vSWscVZ-WB23Y1b_{
	display: flex;
	justify-content: space-around;

}
.SlF9k3ZRL4D_7FcaOWIfE{
	display: flex;
	justify-content: space-around;
	border-bottom:1px solid #ebedf0;
}
._2jvf0J9M-DW4xEQCxxDG42{
width:20%;
padding:10px;
display: flex;
align-items: center;
}
._1DwFpPOGnrd3xC36EjM8Wb{
	padding:8% 20%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}
._33HIyNpOlwXGz_ZvakUEnz{
height: 2px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 40px;
}
._33HIyNpOlwXGz_ZvakUEnz .oKd3MlF_oW0AAIBULVLlx{
	    background: linear-gradient(to right, rgba(24, 144, 255, 0) 0%, #1890ff 100%);
	        height: 100%;
    width: 64px;
        -webkit-animation: _3raOXP1kMgeHsPiCdsyWPx 3s ease-in-out 0s infinite;
                animation: _3raOXP1kMgeHsPiCdsyWPx 3s ease-in-out 0s infinite;
   
}
@-webkit-keyframes _3raOXP1kMgeHsPiCdsyWPx{
	0%, 25% {
    -webkit-transform: translateX(-64px);
    transform: translateX(-64px);
}
75%, 100% {
    -webkit-transform: translateX(45vw);
    transform: translateX(45vw);
}
}
@keyframes _3raOXP1kMgeHsPiCdsyWPx{
	0%, 25% {
    -webkit-transform: translateX(-64px);
    transform: translateX(-64px);
}
75%, 100% {
    -webkit-transform: translateX(45vw);
    transform: translateX(45vw);
}
}

.uQAHzwfOxJv1SQqgrP5Dk{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding:30px 0;
}
._266wCSgKloiXIRhrHh-X-q{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
._266wCSgKloiXIRhrHh-X-q ._2_YCp9pTHqSH51xJikJH-D{
	display: flex;
	justify-content: space-between;
	align-items: center;
	width:160px;
	border-top:1px solid #ebedf0;
	padding-top:20px;
}
._2_YCp9pTHqSH51xJikJH-D .RPATmMx9dSa_cf7y3DPyU{
	font-size:18px;
	cursor: pointer;
}
._3Ge-CIdMbROGJFA06DTnds {
  text-align: center;
}

.S1LueT9M4QSOTquar826M {
  -webkit-animation: _2zbnzgnYyXt1P54dmldOdh infinite 20s linear;
          animation: _2zbnzgnYyXt1P54dmldOdh infinite 20s linear;
  height: 40vmin;
}

._1mzDF1MhAg2ItVJGFPVYW0 {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

._2IVtctMzgDks-Pbg2govDv {
  color: #61dafb;
}

@-webkit-keyframes _2zbnzgnYyXt1P54dmldOdh {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes _2zbnzgnYyXt1P54dmldOdh {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
